<template>
  <el-main>
    <el-form ref="form" :model="addForm" :rules="rules" label-width="120px">
      <el-form-item label="排序：">
        <el-input v-model="addForm.sort"></el-input>
        <p>数字越大，排名越靠前，如为空，排序默认为添加时间越近越靠前</p>
      </el-form-item>
      <el-form-item label="物品类别名称：" prop="name">
        <el-input v-model="addForm.name"></el-input>
      </el-form-item>
      <el-form-item label="物品单位：" prop="units">
        <el-input v-model="addForm.units" placeholder="kg/个/件"></el-input>
      </el-form-item>
      <el-form-item label="类别图片：" prop="picture">
        <ReadyUploadSource @getSource="val => (addForm.picture = val.path)" :path="addForm.picture" @removeThis="() => (addForm.picture = '')"></ReadyUploadSource>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model="addForm.status">
          <el-radio :label="0">下架</el-radio>
          <el-radio :label="1">上架</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="默认价格：" v-if="!addForm.is_spec">
        <div class="spec">
          <table border="1">
            <thead>
              <tr>
                <th>* 用户单价</th>
                <th>* 回收站支付单价</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <el-input v-model="addForm.user_price" type="number" size="small" :min="0"></el-input>
                </td>
                <td>
                  <el-input v-model="addForm.recycle_bin_price" type="number" size="small" :min="0"></el-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-form-item>
      <el-form-item label="是否支持面议：">
        <el-switch v-model="addForm.is_discuss_personally" :active-value="1" :inactive-value="0"></el-switch>
        <span style="margin-left:10px;font-size:13px;">开启则为支持面议，面议品类回收员可以修改回收站支付价格</span>
      </el-form-item>
      <el-form-item label="开启多规格：">
        <el-switch v-model="addForm.is_spec" :active-value="1" :inactive-value="0"></el-switch>
        <div class="spec" v-if="addForm.is_spec">
          <table border="1">
            <thead>
              <tr>
                <th>* 规格名称</th>
                <th>* 规格单价</th>
                <th>* 回收站支付单价</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in addForm.spec" :key="index">
                <td>
                  <el-form-item :prop="'spec.' + index + '.spec_name'" :rules="rules.spec_name">
                    <el-input v-model="item.spec_name" size="small"></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'spec.' + index + '.user_price'" :rules="rules.user_price">
                    <el-input v-model="item.user_price" type="number" size="small" :min="0"></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'spec.' + index + '.recycle_bin_price'" :rules="rules.recycle_bin_price">
                    <el-input v-model="item.recycle_bin_price" type="number" size="small" :min="0"></el-input>
                  </el-form-item>
                  <i class="el-icon-circle-close" @click="removeSpec(index)"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <el-button plain icon="el-icon-plus" @click="addSpec">添加规格</el-button>
        </div>
      </el-form-item>
      <el-form-item label="开启区域规格：">
        <el-switch v-model="addForm.is_open_district_price" @change="changeOpenDistrict" :active-value="1" :inactive-value="0"></el-switch>
        <div class="spec" v-if="addForm.is_open_district_price">
          <table border="1">
            <thead>
              <tr>
                <th>* 所属区域</th>
                <th>* 规格名称</th>
                <th>* 区域单价</th>
                <th>* 区域回收站支付单价</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in addForm.spec_district" :key="index">
                <td width="300">
                  <div style="line-height: 20px">{{ item.cityName }}</div>
                  <el-button type="text" @click="addCurrentSpecCity(index)">添加</el-button>
                </td>
                <td>
                  <el-form-item :prop="'spec_district.' + index + '.spec_name'" :rules="rules.spec_name">
                    <el-select v-model="item.spec_name" size="small">
                      <el-option v-for="(item, index) in addForm.spec" :key="index" :label="item.spec_name" :value="item.spec_name"></el-option>
                    </el-select>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'spec_district.' + index + '.district_user_price'" :rules="rules.district_user_price">
                    <el-input v-model="item.district_user_price" type="number" size="small" :min="0"></el-input>
                  </el-form-item>
                </td>
                <td>
                  <el-form-item :prop="'spec_district.' + index + '.district_recycle_bin_price'" :rules="rules.district_recycle_bin_price">
                    <el-input v-model="item.district_recycle_bin_price" type="number" size="small" :min="0"></el-input>
                  </el-form-item>
                  <i class="el-icon-circle-close" @click="removeCity(index)"></i>
                </td>
              </tr>
            </tbody>
          </table>
          <el-button plain icon="el-icon-plus" @click="addCity">添加区域</el-button>
        </div>
      </el-form-item>
    </el-form>
    <City :alreadyTotalCity="alreadyTotalCity" :showCity="showCity" v-if="showCity" @closeCity="showCity = !1" @getArea="getArea"></City>
    <Preservation @preservation="submit">
      <router-link to="/category/categoryList" slot="return" v-if="id" style="margin-right: 10px">
        <el-button>返回</el-button>
      </router-link>
    </Preservation>
  </el-main>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';
import City from '../../views/set/components/city.vue';
import _ from 'lodash';
export default {
  components: {
    ReadyUploadSource,
    Preservation,
    City,
  },
  data() {
    return {
      id: '',
      showCity: !1,
      alreadyTotalCity: [],
      currentSpecCityIndex: 0,
      addForm: {
        name: '',
        picture: '',
        is_spec: 0,
        status: 1,
        units: '',
        sort: '',
        user_price: 1.2,
        is_discuss_personally: 0,
        is_open_district_price: 0,
        recycle_bin_price: 1.5,
        spec: [
          {
            is_open_district_price: 0,
            spec_name: '',
            cityName: '',
            user_price: '',
            recycle_bin_price: '',
          },
        ],
        spec_district: [
          {
            cityName: '',
            district: [],
            district_user_price: '',
            district_recycle_bin_price: '',
            spec_name: '',
          },
        ],
      },
      rules: {
        name: [
          {
            required: true,
            message: '请填写物品类别名称',
            trigger: 'blur',
          },
        ],
        units: [
          {
            required: true,
            message: '请填写物品单位',
            trigger: 'blur',
          },
        ],
        picture: [
          {
            required: true,
            message: '请添加类别图片',
            trigger: 'blur',
          },
        ],
        spec_name: [
          {
            required: true,
            message: '请填写规格名称',
            trigger: 'blur',
          },
        ],
        user_price: [
          {
            required: true,
            message: '请填写规格单价',
            trigger: 'blur',
          },
        ],
        recycle_bin_price: [
          {
            required: true,
            message: '请填写回收站支付单价',
            trigger: 'blur',
          },
        ],
        district_user_price: [
          {
            required: true,
            message: '请填写区域单价',
            trigger: 'blur',
          },
        ],
        district_recycle_bin_price: [
          {
            required: true,
            message: '请填写区域回收站支付单价',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
    }
  },
  methods: {
    //添加规格
    addSpec() {
      this.addForm.spec.push({
        is_open_district_price: 0,
        spec_name: '',
        user_price: '',
        recycle_bin_price: '',
      });
    },
    //添加区域
    addCity() {
      this.addForm.spec_district.push({
        cityName: '',
        district: [],
        district_user_price: '',
        district_recycle_bin_price: '',
        spec_name: '',
      });
    },
    //删除规格
    removeSpec(index) {
      let spec_district = this.addForm.spec_district;
      if (this.addForm.spec[index].id) {
        this.$axios
          .post(this.$api.O2O.delSpec, {
            id: this.addForm.spec[index].id,
          })
          .then(res => {
            if (res.code == 0) {
              this.addForm.spec_district = spec_district.filter(item => item.spec_name != this.addForm.spec[index].spec_name);
              this.addForm.spec.splice(index, 1);
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        this.addForm.spec_district = spec_district.filter(item => item.spec_name != this.addForm.spec[index].spec_name);
        this.addForm.spec.splice(index, 1);
      }
    },
    //删除区域
    removeCity(index) {
      if (this.addForm.spec_district[index].id) {
        this.$axios.post(this.$api.O2O.delSpecDistrict, { id: this.addForm.spec_district[index].id }).then(res => {
          if (res.code == 0) {
            this.addForm.spec_district.splice(index, 1);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.addForm.spec_district.splice(index, 1);
      }
    },
    //是否开启区域规格
    changeOpenDistrict(val) {
      let spec = this.addForm.spec;
      if (val) {
        spec.map(item => {
          item.is_open_district_price = 1;
        });
      } else {
        spec.map(item => {
          item.is_open_district_price = 0;
        });
      }
    },
    //获取区域
    getArea(val) {
      console.log(val);
      let spec_district = this.addForm.spec_district;
      let district = [];
      for (let i in val) {
        for (let y in val[i]._child) {
          if (val[i]._child[y].checked) {
            district.push({
              id: val[i]._child[y].id,
              name: val[i]._child[y].name,
            });
          }
        }
      }
      if (district.length) {
        let name = '';
        district.map(item => {
          name += item.name + '，';
        });
        spec_district[this.currentSpecCityIndex].district = district;
        spec_district[this.currentSpecCityIndex].cityName = name;
      }
    },
    //添加当前规格区域
    addCurrentSpecCity(index) {
      this.currentSpecCityIndex = index;
      this.showCity = !0;
    },
    getInfo() {
      this.$axios
        .post(this.$api.O2O.recycleInfo, {
          id: this.id,
        })
        .then(res => {
          if (res.code == 0) {
            let spec = [];
            let info = res.result;
            let addForm = this.addForm;
            addForm.name = info.name;
            addForm.is_spec = info.is_spec;
            addForm.sort = info.sort;
            addForm.picture = info.picture;
            addForm.status = info.status;
            addForm.units = info.units;
            addForm.is_discuss_personally = info.is_discuss_personally;
            if (info.is_spec) {
              for (let i in info.spec) {
                spec.push({
                  is_open_district_price: info.spec[i].is_open_district_price,
                  spec_name: info.spec[i].spec_name,
                  user_price: info.spec[i].user_price,
                  recycle_bin_price: info.spec[i].recycle_bin_price,
                  id: info.spec[i].id,
                });
              }
              addForm.spec = spec;
            } else {
              addForm.user_price = info.spec[0].user_price;
              addForm.recycle_bin_price = info.spec[0].recycle_bin_price;
            }
            if (info.spec[0].is_open_district_price) {
              let spec_district = [];
              for (let i in info.spec) {
                for (let y in info.spec[i].spec_district) {
                  let district = [];
                  info.spec[i].spec_district[y].district.map(item => {
                    district.push({
                      id: item,
                    });
                  });
                  info.spec[i].spec_district[y].district_name.map((item, index) => {
                    district[index].name = item;
                  });
                  spec_district.push({
                    district: district,
                    cityName: info.spec[i].spec_district[y].district_name.join(),
                    city: info.spec[i].spec_district[y].district,
                    district_user_price: info.spec[i].spec_district[y].district_user_price,
                    district_recycle_bin_price: info.spec[i].spec_district[y].district_recycle_bin_price,
                    spec_name: info.spec[i].spec_name,
                    id: info.spec[i].spec_district[y].id,
                  });
                }
                spec[i].id = info.spec[i].id;
                spec[i].is_open_district_price = 1;
              }
              addForm.is_open_district_price = 1;
              console.log(addForm.is_open_district_price);
              addForm.spec_district = spec_district;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addForm = _.cloneDeep(this.addForm);
          let spec_district = addForm.spec_district;
          let spec = [];
          for (let i in addForm.spec_district) {
            if (!addForm.spec_district[i].cityName && addForm.is_open_district_price) return this.$message.warning('请添加区域');
          }
          if (addForm.is_spec) {
            for (let i in addForm.spec) {
              let obj = {
                is_open_district_price: 0,
                spec_name: addForm.spec[i].spec_name,
                user_price: addForm.spec[i].user_price,
                recycle_bin_price: addForm.spec[i].recycle_bin_price,
              };
              if (addForm.spec[i].id) obj.id = addForm.spec[i].id;
              spec.push(obj);
            }
          } else {
            spec.push({
              user_price: addForm.user_price,
              recycle_bin_price: addForm.recycle_bin_price,
            });
          }
          if (addForm.is_open_district_price) {
            for (let i in addForm.spec) {
              let district = [];
              for (let y in spec_district) {
                let obj = {
                  district: [],
                };
                if (addForm.spec[i].spec_name == spec_district[y].spec_name) {
                  spec_district[y].district.map(item => obj.district.push(item.id));
                  if (spec_district[y].id) obj.id = spec_district[y].id;
                  obj.district_user_price = spec_district[y].district_user_price;
                  obj.district_recycle_bin_price = spec_district[y].district_recycle_bin_price;
                  district.push(obj);
                }
              }
              addForm.spec[i].spec_district = district;
              addForm.spec[i].is_open_district_price = 1;
            }
          }
          for (let i in addForm.spec) {
            delete addForm.spec[i].city;
            delete addForm.spec[i].cityName;
          }
          let data = {
            sort: addForm.sort || 99,
            name: addForm.name,
            units: addForm.units,
            is_discuss_personally: addForm.is_discuss_personally,
            picture: addForm.picture,
            is_spec: addForm.is_spec,
            status: addForm.status,
            spec: addForm.spec,
          };
          if (this.id) data.id = this.id;
          this.$axios.post(this.id ? this.$api.O2O.editRecycle : this.$api.O2O.addRecycle, data).then(res => {
            if (res.code == 0) {
              this.$message.success(this.id ? '编辑成功' : '添加成功');
              this.$router.push('/category/categoryList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  padding-bottom: 50px;
  .el-form {
    .el-input {
      width: 450px;
    }
    .spec {
      table {
        margin-bottom: 15px;
        border: 1px solid #dcdfe6;
        thead {
          th {
            padding: 0 10px;
          }
        }
        tbody {
          td {
            text-align: center;
            padding: 20px;
            position: relative;
            span {
              line-height: 20px;
            }
            i {
              position: absolute;
              top: 5px;
              right: 5px;
              font-size: 20px;
              cursor: pointer;
            }
            .el-input {
              width: 200px;
            }
          }
        }
      }
    }
  }
}
</style>
